import React from "react";
import { getUser } from "../../services/Storage.Utils";

const Avatar = () => {
  return (
    <img
      src={getUser().avatar}
      className="rounded-circle img-fluid"
      alt=""
    />
  );
};

export default Avatar;
