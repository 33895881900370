import hookup from "./hookup";

let path = "api/v2/";

export const SingIn = async (data) => {
    return await hookup("post", `${path}login`, data);
};
export const Recover = async (data) => {
    return await hookup("post", `${path}recover/password`, data);
};
export const UpdatePerfil = async (data) => {
    return await hookup("post", `${path}update/perfil`, data);
};
export const RegisterGetQuestion = async (data) => {
    return await hookup("post", `${path}sinacofi/get/questions`, data);
};
export const RegisterValidateQuestion = async (data) => {
    return await hookup("post", `${path}sinacofi/validate/answer`, data);
};
export const SaveRegister = async (data) => {
    return await hookup("post", `${path}register`, data);
};
export const GetContratoDigital = async (data) => {
    return await hookup("post", `${path}agreements/document`, data);
};

export const ActionBuyTransbank = async (data) => {
    return await hookup("get", `api/mall/start/mi-sendero`, data);
};
export const ActionBuyKhipu = async (data) => {
    return await hookup("post", `payments/khipu/create`, data);
};

export const ActionAcceptarContrato = async (data) => {
    return await hookup("post", `${path}actualizar_aceptantes`, data);
};

export const recaptcha = async (data) => {
    return await hookup("post", `${path}recaptcha`, data);
};

export const GetAvatar = async (rut) => {
    return await hookup("get", `${path}avatar/${rut}`, {});
}