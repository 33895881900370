import React from 'react';
import { Button, FormGroup, Spinner } from 'reactstrap';
import Api from '../../service/Api';
import Session from '../../service/Session';
import { LogoSendero } from './Logo';
import Validate from '../../service/Validate';
class FormAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      rut: '',
      disabledButton: true,
      msg: '',
      rsp: 200,
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.auth = this.auth.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
  }
  onChange = (event) => {
    // let valor = event.target.value;
    // valor = valor
    //     .replace(/[.-]/g, "")
    //     .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    const new_rut = Validate.formatterRut(event.target.value);
    this.setState({
      rut: new_rut,
    });
  };
  async auth() {
    this.setState({ show: true });

    const dat = {};
    const { rut } = this.state;
    if (rut === '') {
      this.setState({
        msg: 'Debe ingresar un rut',
        rsp: 404,
        show: false,
      });
      return false;
    }
    dat.rut = rut;
    const { result, response } = await Api.loginPagoRapido(dat);
    if (response === 200) {
      if (result.USUARIO !== 'Rut no es cliente Sendero') {
        Session.setTokenUserPagoRapido(
          result.token,
          rut,
          result.CORREO,
          result.USUARIO
        );
        window.location.href = '/pago-rapido/';
      } else {
        this.setState({
          msg: 'Rut no es cliente Sendero',
          rsp: 404,
          show: false,
        });
      }
    } else {
      this.setState({
        msg: result.resultError,
        rsp: response,
        show: false,
      });
    }
  }
  render() {
    const { col } = this.props;
    const { msg, rsp, show, rut } = this.state;
    var clas =
      col === 5
        ? 'col-12 col-md-5 offset-md-3 car'
        : 'col-12 col-md-10 offset-md-1';
    return (
      <>
        <div id="content_login_rut" className="content-login-popup">
          <div className="row">
            <div className={clas}>
              <header>
                <LogoSendero />
              </header>
              <form id="consulta_rut_public">
                <div className="row">
                  <div className="col-12">
                    <FormGroup row>
                      <div className="col-12 text-center">
                        <p className="title-rut">
                          <strong>Ingrese Rut Titular</strong>
                        </p>
                      </div>
                    </FormGroup>
                    <FormGroup row>
                      <div className="col-12 text-center">
                        <input
                          type="text"
                          name="rut"
                          id="rut"
                          value={rut || ''}
                          onChange={this.onChange}
                          placeholder="11.111.111-1"
                          className="form-control"
                        />
                      </div>
                    </FormGroup>
                    {parseInt(rsp) === 404 && (
                      <FormGroup row>
                        <div className="col-12 text-center">
                          <div className="alert alert-danger">{msg}</div>
                        </div>
                      </FormGroup>
                    )}
                    <FormGroup row>
                      <div className="col-12 text-center">
                        <Button
                          className="btn btn-verde"
                          onClick={this.auth}
                          disabled={show}
                        >
                          {show ? (
                            <Spinner
                              style={{ width: '2rem', height: '2rem' }}
                            />
                          ) : (
                            <>Ingresar</>
                          )}
                        </Button>
                      </div>
                    </FormGroup>
                    <FormGroup row>
                      <div className="col-12 text-center">
                        <a
                          href="/"
                          target="_self"
                          className="btn btn-verde volver"
                        >
                          Volver a Sendero
                        </a>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default FormAuth;
