import React from 'react';
import FormularioLogin from './FormularioLogin.jsx';
import PostsItems from "../../components/Posts/Items";
import { Link } from 'react-router-dom';
import logoSendero from '../../assets/images/logo-sendero.svg';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { activo, activarMenu, registrar , iscontrato} = this.props;
        var record = registrar;
        return (
            <>
                {activo ?
                    (
                        <Link className="btn-mi-sendero btn btn-verde" to={'/mi-sendero'}>
                            <p className="title-mobile-menu"> Mi Sendero</p>
                        </Link>
                    ) : (
                        <>
                            <div className={iscontrato === 1 ? "" : "main"}>
                                <div className="menu">
                                    <div className="content">
                                        <div className="menu-sendero">
                                            <div className="container menu-sendero__content" style={{'margin-top': iscontrato === 1 ? '' : '125px' }}>
                                                {
                                                    iscontrato === 1 ? (
                                                        <>
                                                            <div className="content-logo text-center">
                                                                <img src={logoSendero} style={{height: '75px' , marginBottom: '50px'}}/>
                                                            </div>
                                                        </>          
                                                    ) : (<></>)
                                                }
                                                <div className="row">
                                                    <div className={iscontrato === 1 ? "col-md-6 offset-md-3" : "col-12 offset-md-1 col-md-4"}>
                                                        <div className={iscontrato === 1 ? "content-login text-center" : "content-login"}>
                                                            <h3>{iscontrato !== 1 ? "Ingresa a Mi Sendero" : "Ingreso a firma de contrato"}</h3>
                                                            <FormularioLogin registrar={record} iscontrato={iscontrato}/>
                                                        </div>
                                                    </div>
                                                    <div className={iscontrato === 1 ? "d-none" : "col-12 col-md-7"}>
                                                    {/* <div className="col-12 col-md-7"> */}
                                                        <h3>Conoce nuestras novedades</h3>
                                                        <div className="content-posts">
                                                            <div className="content-items-posts">
                                                                <PostsItems
                                                                    type="3"
                                                                    title=""
                                                                    cant="3"
                                                                    activarMenu={activarMenu}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}

export default Home;