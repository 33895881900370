export function logout() {
  localStorage.removeItem("contrato");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("nombre");
  localStorage.removeItem("rut");
  localStorage.removeItem("email");
}

export function getUser() {
  return {
    nombre: JSON.parse(localStorage.getItem('user')).nombre,
    rut: JSON.parse(localStorage.getItem('user')).rut,
    email: JSON.parse(localStorage.getItem('user')).email,
    direccion: JSON.parse(localStorage.getItem('user')).direccion,
    celular: JSON.parse(localStorage.getItem('user')).celular,
    telefono: JSON.parse(localStorage.getItem('user')).telefono,
    avatar: JSON.parse(localStorage.getItem('user')).avatar,
  }
}
export function getContrato() {
  return JSON.parse(localStorage.getItem('contrato'))
}
export function getToken() {
  return localStorage.getItem('token')
}
export function setUserLogin(token, user) {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('update', 1)
}