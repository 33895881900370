class Session {
	openTmpPopUp = (token, rut, nd, usuario, correo, email = "", fono = "", session = 0, prorroga) => {
		localStorage.setItem('token', token)
		localStorage.setItem('rut', rut.replace('.', ''))
		localStorage.setItem('nd', nd)
		localStorage.setItem('usuario', usuario)
		localStorage.setItem('correo', correo)
		localStorage.setItem('email', email)
		localStorage.setItem('fono', fono)
		localStorage.setItem('session', session)
		localStorage.setItem('prorroga', prorroga)
	}
	isAuth = () => {
		let isAuth = ("token" in localStorage)
		return isAuth
	}
	setTokenUserPagoRapido = (token, Prut, Pcorreo, Pusuario) => {
		localStorage.setItem('token', token)
		localStorage.setItem('Prut', Prut)
		localStorage.setItem('Pcorreo', Pcorreo)
		localStorage.setItem('Pusuario', Pusuario)
	}
	getTokenUserPagoRapido = () => {
		return {
			token: localStorage.getItem("token")
		}
	}

	getInfoUserPagoRapido = () => {
		return {
			token: localStorage.getItem("token"),
			Prut: localStorage.getItem("Prut"),
			Pcorreo: localStorage.getItem("Pcorreo"),
			Pusuario: localStorage.getItem("Pusuario"),
		}
	}
	getTokenUser = () => {
		return {
			token: localStorage.getItem("token")
		}
	}
	getInfoUser = () => {
		return {
			rut: localStorage.getItem("rut"),
			nd: localStorage.getItem("nd"),
			correo: localStorage.getItem("correo"),
			email: localStorage.getItem("email"),
			fono: localStorage.getItem("fono"),
			usuario: localStorage.getItem("usuario"),
			session: localStorage.getItem("session"),
			prorroga: localStorage.getItem("prorroga"),
			iscontrato: localStorage.getItem("iscontrato")
		}
	}
	getContratos = () => {
		return {
			contrato: JSON.parse(localStorage.getItem("contrato"))
		}
	}
	getMantencion = () => {
		return {
			mantencion: localStorage.getItem("mantencion"),
			pesos: localStorage.getItem("pesos"),
			uf: localStorage.getItem("uf")
		}
	}
	setInfoContratos = (contrato) => {
		localStorage.setItem('contrato', JSON.stringify(contrato))
	}
	setInfoMantencion = (mantencion, pesos, uf) => {
		localStorage.setItem('mantencion', mantencion)
		localStorage.setItem('pesos', pesos)
		localStorage.setItem('uf', uf)
	}
	close = () => {
		localStorage.clear()
	}

	getInformacionContratos = () => {
		return {
			infcontratos: JSON.parse(localStorage.getItem("infcontratos"))
		}
	}
}
export default new Session()