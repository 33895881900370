import React, { Component } from "react";
import { Link } from "react-router-dom";
class Url extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      url,
      target,
      parent,
      name,
      local,
      component,
      classmenu,
      ShowMegaMenu,
    } = this.props;
    return (
      <div className={"item " + classmenu} onClick={ShowMegaMenu}>
        {local ? (
          <>
            <a href={url} target={target}>
              <p>
                {name}
                {parent && <i class="fas fa-chevron-right"></i>}
              </p>
            </a>
            {typeof component !== "undefined" && component}
          </>
        ) : (
          <>
            <Link to={url} onClick={ShowMegaMenu}>
              <p>
                {name}
                {parent && <i class="fas fa-chevron-right"></i>}
              </p>
            </Link>
            {typeof component !== "undefined" && component}
          </>
        )}
      </div>
    );
  }
}
export default Url;