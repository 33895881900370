import React from "react";

import "./Detail.scss";

const Detail = (props) => {
  console.log(props);
  return (
    <>
      <div className="detail-contrato d-none d-md-block">
        <h2>{props.producto}</h2>
        <div className="row">
          <div className="col-4">
            <div className="box">
              <h3>Parque</h3>
              <p>{props.parque}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="box">
              <h3>Detalle</h3>
              <p>{props.producto}</p>
              <p>Capacidad: {props.capacidad}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="box">
              {/* <h3>Contrato</h3>
              <p>Contrato N°: {props.contrato}</p>
              <p>Fecha Contrato: {props.fecha_contrato}</p>
              <p>Precio Producto: {props.saldo_total}</p> */}
              <h3>Contrato</h3>
              <p>Contrato N°: {props.contrato}</p>
              {/* <p>Fecha Contrato: {props.fecha_contrato}</p> */}
              <p>Fecha Contrato: {props.pie?.vigente?.adicional?.fecha_contrato ?? props.fecha_contrato}</p>
              {/* <p>Precio Producto: {props.saldo_total}</p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
