import validator from 'validator';

class Validate {
  ValidateForm(value, type, texto) {
    var mensaje = '';
    switch (type) {
      case 'numerico':
        if (!validator.isNumeric(value)) {
          mensaje = '- El ' + texto + ' debe tener solo caracteres válidos\n';
        }
        if (value.length < 9 || value.length > 10) {
          mensaje =
            '- El ' +
            texto +
            ' debe tener un mínimo de 9 caracteres y máximo de 10 caracteres\n';
        }
        break;
      case 'extension':
        if (!validator.isNumeric(value)) {
          mensaje = '- El ' + texto + ' debe tener solo caracteres válidos\n';
        }
        if (value.length < 0 || value.length > 3) {
          mensaje = '- El ' + texto + ' debe tener entre 1 a 3 dígitos\n';
        }
        break;
      case 'email':
        if (!validator.isEmail(value)) {
          mensaje = '- El ' + texto + ' debe ser válido\n';
        }
        break;
      case 'rut':
        let val = value.replace(/\./g, '');
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(val)) {
          mensaje = '- El ' + texto + ' debe tener solo caracteres válidos\n';
        }
        break;
      case 'texto':
        if (validator.isEmpty(value)) {
          mensaje =
            '- El ' +
            (texto === 'ApellidoP'
              ? 'Apellido Paterno'
              : texto === 'ApellidoM'
              ? 'Apellido Materno'
              : texto) +
            ' debe tener solo caracteres válidos\n';
        }
        break;
      case 'texto1':
        if (validator.isEmpty(value)) {
          mensaje =
            '- El ' +
            (texto === 'ApellidoP'
              ? 'Apellido Paterno'
              : texto === 'ApellidoM'
              ? 'Apellido Materno'
              : texto) +
            ' debe tener solo caracteres válidos\n';
        }
        break;
      case 'texto2':
        if (validator.isEmpty(value)) {
          mensaje =
            '- El ' +
            (texto === 'ApellidoP'
              ? 'Apellido Paterno'
              : texto === 'ApellidoM'
              ? 'Apellido Materno'
              : texto) +
            ' debe tener solo caracteres válidos\n';
        }
        break;
      case 'texto3':
        if (validator.isEmpty(value)) {
          mensaje =
            '- El ' +
            (texto === 'ApellidoP'
              ? 'Apellido Paterno'
              : texto === 'ApellidoM'
              ? 'Apellido Materno'
              : texto) +
            ' debe tener solo caracteres válidos\n';
        }
        break;
      case 'mensaje':
        if (validator.isEmpty(value)) {
          mensaje =
            '- El ' +
            (texto === 'ApellidoP'
              ? 'Apellido Paterno'
              : texto === 'ApellidoM'
              ? 'Apellido Materno'
              : texto) +
            ' debe tener solo caracteres válidos\n';
        } else {
          if (value.length <= 14) {
            mensaje =
              '- El ' + texto + ' debe tener un mínimo de 15 caracteres\n';
          }
        }
        break;
      case 'selector':
        if (
          value === 0 ||
          value === '0' ||
          value === null ||
          value === '' ||
          value === 'Seleccionar Tipo Necesidad'
        ) {
          mensaje = '- Debe seleccionar ' + texto + '\n';
        }
        break;

      case 'selector1':
        if (
          value === 0 ||
          value == '0' ||
          value === null ||
          value === '' ||
          value === 'Seleccionar Tipo Necesidad'
        ) {
          mensaje = '- Debe seleccionar ' + texto + '\n';
        }
        break;

      case 'selector2':
        if (
          value === 0 ||
          value == '0' ||
          value === null ||
          value === '' ||
          value === 'Seleccionar Tipo Necesidad'
        ) {
          mensaje = '- Debe seleccionar ' + texto + '\n';
        }
        break;
      default:
        mensaje = '';
        break;
    }
    return mensaje;
  }
  validate(dataForm, validations) {
    Object.keys(dataForm).map((index) => {
      var i = dataForm[index];
      var parent = i.parentNode;
      if (typeof parent === 'object') {
        var feed = parent.getElementsByClassName('invalid-feedback');
        var search = i.name in validations;
        i.className = i.className.replace('is-invalid', '');
        parent.className = parent.className.replace('form-check-invalid', '');
        if (feed.length > 0) {
          feed[0].remove();
        }
        if (search) {
          var wrapper = document.createElement('div');
          wrapper.innerHTML = validations[i.name];
          wrapper.className = 'invalid-feedback';
          if (parent.classList.contains('form-check-inline')) {
            parent.classList.add('form-check-invalid');
          }
          parent.appendChild(wrapper);
          i.className = 'form-control is-invalid';
        }
      }
      return true;
    });
  }

  validaRut(rutCompleto) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';

    if (this.dv(rut) == digv) {
      return true;
    }

    return false;
  }

  dv(T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : 'k';
  }

  isTelefonoOk(stelefono) {
    let objResult = {
      bresult: true,
      mresult: '',
    };

    if (stelefono.trim().length <= 0) {
      objResult.bresult = false;
      objResult.mresult = 'Debe Ingresar un teléfono válido.';
      return objResult;
    }

    if (stelefono.trim().length != 9) {
      objResult.bresult = false;
      objResult.mresult = 'El teléfono solo puede tener 9 digitos.';
      return objResult;
    }

    if (
      stelefono.trim().substring(0) == '1' ||
      stelefono.trim().substring(0) == '0'
    ) {
      objResult.bresult = false;
      objResult.mresult = 'El teléfono no puedo empezar con 0 o 1.';
      return objResult;
    }

    if (
      stelefono.trim() == '222222222' ||
      stelefono.trim().substring(0) == '333333333' ||
      stelefono.trim().substring(0) == '444444444' ||
      stelefono.trim().substring(0) == '555555555' ||
      stelefono.trim().substring(0) == '666666666' ||
      stelefono.trim().substring(0) == '777777777' ||
      stelefono.trim().substring(0) == '888888888' ||
      stelefono.trim().substring(0) == '999999999' ||
      stelefono.trim().substring(0) == '987654321'
    ) {
      objResult.bresult = false;
      objResult.mresult = 'El teléfono ingresado no es válido.';
      return objResult;
    }

    return objResult;
  }

  formatterRut(valor) {
    let new_rut = '';
    if (valor.length <= 7) {
      let aux = valor.replace(/[^\dk]/gi, '');

      new_rut =
        aux.substr(0, aux.length - 1).replace(/(\d{3})(?=\d)/g, '$1.') +
        '-' +
        aux.substr(aux.length - 1, 1);
    } else {
      new_rut = valor
        // .replace(/[.-]/g, '')
        .replace(/[^\dk]/gi, '')
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    }
    // console.log(new_rut);
    return new_rut;
  }
}
export default new Validate();
