import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import LogoSendero from '../../../assets/images/logo/LogoSendero.png';
import Icon247 from '../../../assets/images/icons/24-7.png';
import FooterFixed from './footer-fixed';
import Auth from '../../../pago-rapido/auth';
import Logo from '../../Logo';

class Footer extends Component {
  async handleMenuClick(event) {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Container>
          <div className="footer-container">
            <div className="footer-container__row">
              <Row>
                <Col xs={12} className="text-center">
                  <Logo add_class={'header'} />
                  <div className="footer-container__socials text-center">
                    <p className="rrss">
                      <a
                        href="https://www.facebook.com/Senderoparque/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-bottom-rrss fab fa-facebook-f"></i>{' '}
                      </a>
                      <a
                        href="https://www.instagram.com/senderoparque/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-bottom-rrss fab fa-instagram"></i>{' '}
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCv8fufJ35tJDP2E92NdHgww"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icon-bottom-rrss fab fa-youtube"></i>{' '}
                      </a>
                    </p>
                  </div>
                </Col>
                <Col xs={12} className="text-center">
                  <ul className="list-footer">
                    <li className="item-footer-title">Atención y Ayuda</li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/oficinas',
                          hash: '#root',
                        }}
                      >
                        <p>Nuestras Oficinas</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/canal-de-denuncia',
                          hash: '#the-hash',
                        }}
                      >
                        <p>Canal Denuncias Complimiento</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/canal-de-denuncia-etico',
                          hash: '#the-hash',
                        }}
                      >
                        <p>Canal de Denuncias Éticas</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      {/* <Link onClick={this.handleMenuClick} to={{
                        pathname: "/que-debo-hacer-en-caso-de-fallecimiento",
                        hash: "#root",
                      }}>
                        <p>Qué Hacer En Caso De Fallecimiento</p>
                      </Link> */}
                      <a
                        href={
                          'https://masinformados.sendero.cl/que-hacer-en-caso-de-fallecimiento/'
                        }
                        className="hover-link"
                        target={'_blank'}
                      >
                        <p>Qué Hacer En Caso De Fallecimiento</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      {/* <Link onClick={this.handleMenuClick} to={{
                        pathname: "/que-debo-hacer-en-caso-de-fallecimiento",
                        hash: "#root",
                      }} smooth="true">
                        Qué Documentos Necesito Para La Cremación
                      </Link> */}
                      <a
                        href={
                          'https://masinformados.sendero.cl/que-hacer-en-caso-de-fallecimiento/'
                        }
                        className="hover-link"
                        target={'_blank'}
                      >
                        <p>Qué Documentos Necesito Para La Cremación</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <a
                        href={
                          'https://masinformados.sendero.cl/precios-sendero/'
                        }
                        className="hover-link"
                        target="_blank"
                      >
                        <p>Valores Sendero 2023</p>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} className="text-center">
                  <ul className="list-footer">
                    <li className="item-footer-title">Dónde Pagar</li>
                    <li className="item-footer">
                      <Auth type="top-footer" popup={true} />
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        smooth="true"
                        to={{
                          pathname: '/donde-pagar',
                          hash: '#the-hash',
                        }}
                      >
                        <p>Caja Sendero</p>
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} className="text-center">
                  <ul className="list-footer">
                    <li className="item-footer-title">Sendero</li>
                    <li className="item-footer">
                      {/* <Link onClick={this.handleMenuClick} to={{
                        pathname: "/contratos-y-reglamentos",
                        hash: "#root"
                      }}>
                        <p>Contratos y Reglamentos</p>
                      </Link> */}
                      <a
                        class="hover-link"
                        target={'_blank'}
                        href={
                          'https://masinformados.sendero.cl/contratos-y-reglamentos/'
                        }
                      >
                        <p>Contratos y Reglamentos</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <a
                        href="https://sendero.trabajando.cl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>Trabaje con nosotros</p>
                      </a>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/transparencia',
                          hash: '#root',
                        }}
                      >
                        <p>Transparencia</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/politicas-de-privacidad',
                          hash: '#root',
                        }}
                      >
                        <p>Politica de Privacidad</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={'/politica-de-calidad'}
                        className="hover-link"
                      >
                        <p>Política de calidad</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/sobre-sendero',
                          hash: '#root',
                        }}
                      >
                        <p>Sobre Sendero</p>
                      </Link>
                    </li>
                    <li className="item-footer">
                      <Link
                        onClick={this.handleMenuClick}
                        to={{
                          pathname: '/preguntas-frecuentes',
                          hash: '#root',
                        }}
                      >
                        <p>Preguntas Frecuentes</p>
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col md={3} className="text-center">
                  <span className="item-footer-title">Estamos aquí</span>
                  <br />
                  <span className="item-footer-title">Cuando nos necesite</span>
                  <br />
                  <span className="item-footer-title">
                    <strong>CONTÁCTENOS LAS 24 HORAS</strong>
                  </span>
                  <div className="footer-contact">
                    <div>
                      <img src={Icon247} className="img img-247" alt="" />
                    </div>
                    <div className="">
                      <a className="footer-phone" href="tel:6007363376">
                        <strong>600 736 3376</strong>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="post-footer">
                <Col xs={12} className="post-footer__info text-center">
                  © 2023 Parque del Sendero
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        <FooterFixed />
      </div>
    );
  }
}
export default Footer;
