import React, { useState } from "react";
import webpayTransbank from './../../../pago-rapido/Assets/Images/webpay_transbank.jpg';
import khipu from './../../../pago-rapido/Assets/Images/khipu.jpg';
import Button from "../Button/Button";
import Alert from "./../Alert/Alert";
import { POST } from '../../services/Api';
import { getUser } from "../../services/Storage.Utils";
import AmountPay from "./AmountPay";
import * as Api from "../../api";
import './Pay.scss';

const ButtonPay = ({ cuotasSeleccionadas, amount, disabledPagar, nameBtPay, nameBtCartola }) => {
  const [paymentType, setPaymentType] = useState(1);
  const [url, setUrl] = useState(0);
  const [token_ws, setTokenWs] = useState(0);
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState('');

  function handleInputChangeOption(event) {
    const target = event.target;
    const value = target.value;
    setPaymentType(value);
  }
  const onClickPagarWebPay = async () => {

    if(amount == null || amount <= 0){
      setStatus('danger');
      setMsg('Necesita colocar Monto');
      return false;
    }
    console.info(cuotasSeleccionadas)
    setLoader(true);
    const data = await Api.ActionBuyTransbank({
      rut: getUser().rut,
      email: getUser().email,
      nombre: getUser().nombre,
      cuotasSeleccionadas,
      amount: amount,
    });
    const { result, next_page, token_ws } = data;
    if (typeof data !== 'undefined') {
      setStatus(result);
      setLoader(false);
      if (result === 'success') {
        setUrl(next_page);
        setTokenWs(token_ws);
        document.getElementById("form_webpay").submit();
      } else {
        setStatus('danger');
        setMsg('Ocurrió un problema al conectar con Transbank');
      }
    } else {
      setLoader(false);
      setStatus('danger');
      setMsg('Error al conectar');
    }
  }
  const onClickPagarKiphu = async () => {
    
    if(amount == null || amount <= 0){
      setStatus('danger');
      setMsg('Necesita colocar Monto');
      return false;
    }

    setLoader(true);
    const data = await Api.ActionBuyKhipu({
      rut: getUser().rut,
      email: getUser().email,
      nombre: getUser().nombre,
      cuotasSeleccionadas,
      amount: amount
    });
    if (typeof data !== 'undefined') {
      const { result, status } = data;
      setStatus(status);
      setLoader(false);
      if (status === 'success') {
        const { payment_url } = result;
        setUrl(payment_url);
        document.getElementById("form_webpay").submit();
      } else {
        setStatus('danger');
        setMsg('Ocurrió un problema al conectar con Khipu');
      }
    } else {
      setLoader(false);
      setStatus('danger');
      setMsg('Error al conectar');
    }
  }
  return (
    <div className="box-method-pay">
      <form name="form_webpay" action={url} method="POST" id="form_webpay">
        <input type="hidden" name="token_ws" value={token_ws} />
      </form>
      <AmountPay amount={amount} />
      <div className="checkout box">
        <div className="sl-method-pay row mt-3 text-center">
          <label className="col-12 text-md-left"><h4 className="text-uppercase mb-2">Seleccione el método de pago</h4></label>
          <div className="col-6">
            <label className="radio2 box">
              <div className="row">
                <div className="col-12 col-md-2">
                  <input type="radio" className="mnt-opcion" name="paymentType" defaultChecked defaultValue="1" value={1} style={{ zoom: '2' }} onChange={(e) => handleInputChangeOption(e)} />
                </div>
                <div className="col-12 col-md-10 text-left">
                  <p>Tarjeta Crédito / Débito</p>
                  <img alt="" src={webpayTransbank} />
                  <span className="check2"></span>
                </div>
              </div>
            </label>
          </div>
          <div className="col-6">
            <label className="radio2 box">
              <div className="row">
                <div className="col-12 col-md-2">
                  <input type="radio" name="paymentType" value={2} style={{ zoom: '2' }} onChange={(e) => handleInputChangeOption(e)} />
                </div>
                <div className="col-12 col-md-10 text-left">
                  <p>Transferencia desde su banco</p>
                  <img alt="" src={khipu} />
                  <span className="check2"></span>
                </div>
              </div>
            </label>
          </div>
        </div>
        <Alert msg={msg} status={status} />
        <div className="row">
          <div className="col-12 offset-md-4 col-md-4 text-center">
            <div className="d-block d-md-none">
              <Button
                classes={'bt-ms _bt-blue'}
                loader={loader}
                name={nameBtCartola}
              />
            </div>
          </div>
          <div className="col-12 offset-md-4 col-md-4 text-center">
            <Button
              classes={'bt-ms _bt-green'}
              handleLogin={paymentType === 1 ? onClickPagarWebPay : onClickPagarKiphu}
              loader={loader}
              name={nameBtPay}
              disabled={disabledPagar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonPay;
