import React from "react";
import Logo from "../../../components/Logo";
import Left from "../Sidebar/Left";
import MegaMenu from "../../../components/Header";
import Horizontal from "../Sidebar/Horizontal";
import { Link } from "react-router-dom";
import { BaseUrlNewSendero } from '../../../config';

const NameUser = () => {
  return (
    <header className="header-1 text-center">
      <div className="d-block d-md-none">
        <Link to={`/${BaseUrlNewSendero}/resumen`}>
          <i className="fas fa-chevron-left arrow-menu-header"></i>
        </Link>
        <Logo />
        <Left />
      </div>
      <div className="d-none d-md-block">
        <MegaMenu component={<Horizontal />} />
      </div>
    </header>
  );
};

export default NameUser;
