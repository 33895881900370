import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import Login from './Page/Login/Login/Login';
import Recover from './Page/Recover/Recover';
import Register from './Page/Register/Register';
import { getToken } from './services/Storage.Utils';
import routesPrivate from './Page/routesPrivate';
import { BaseUrlNewSendero } from '../config';
import DetailPago from '../components/DetailPago/DetailPago';
import Layout from '../pago-rapido/Layout';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = getToken();
    if (token !== null) {
        return (
            <Route component={() => <Component {...rest} />} />
        );
    }
    return <Redirect to={`/${BaseUrlNewSendero}/`} />;
    

};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};
const renderContent = (props) => {
    const Path = props.match.path;
    return (
        getToken() != null ?
        <BrowserRouter>
            <PrivateRoute path={`${Path}/`} component={routesPrivate} />
        </BrowserRouter>
        : 
        <BrowserRouter>
            <Route path={`${Path}detail/:origen/:buy_order/:token/:status/:code`} component={() => <><Layout><DetailPago /></Layout></>} />
            <Route exact path={`${Path}/`} component={() => <><Layout><Login /></Layout></>} />
            <Route exact path={`${Path}/register`} component={() => <><Layout><Register /></Layout></>} />
            <Route exact path={`${Path}/recover`} component={() => <><Layout><Recover /></Layout></>} />
            <PrivateRoute path="/" component={routesPrivate} />
        </BrowserRouter>
    );

}

export default renderContent;