import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Title from "../../components/Title/BoxText";
import Layout from "../Login/Layout/Layout";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import * as Api from "../../api";
import { BaseUrlNewSendero , Recaptcha_key } from '../../../config';
import ReCAPTCHA from "react-google-recaptcha";

const Recover = (props) => {

  const recaptchaRef = React.useRef();
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState({
    rut: "",
  });

  const handleRecover = async () => {
    setLoader(true);
    
    const { rut } = inputs;
    
    if (rut === "") {
      setMsg("Debe ingresar un rut");
      setStatus('danger');
      setLoader(false);
      return false;
    }
    
    const token = await recaptchaRef.current.executeAsync();

    const recaptcha = await Api.recaptcha({ token: token });

    if (recaptcha.validation) {

      const response = await Api.Recover(inputs);
      const { result } = response;
      
      setStatus(response.status);
      
      if (response.status === "success") {
        setMsg(result.msg);
      } else {
        setMsg(result.msg);
      }
      
      setLoader(false);

    } else {
      
      setMsg(recaptcha.msg);
      setStatus(recaptcha.status);
      setLoader(false);
  }

  
    
  };
  const handleInput = (e) => {
    let valor = e.target.value;
    if (e.target.name === "rut") {
      
      valor = valor
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
     
    }

    setInputs({
      ...inputs,
      [e.target.name]: valor,
    });
    // setInputs({
    //     ...inputs,
    //     [e.target.name]: e.target.value,
    // });
  };

  return (
    <>
        {
            props.iscontrato === 1 ? (<>
            <div className="container-fluid d-flex justify-content-center align-items-center vh-100 align-content-center">
              <div className="row">
                <div className="col-md-12 bg-mobile">
                  <div className="ct-public__form">
                    <Title
                      title1={"Recuperar"}
                      title2={"Contraseña"}
                      subtitle={
                        "Ingresa tu RUT y enviaremos una clave provisoria al correo registrado."
                      }
                    />
                    <form>
                      <div className="content-form">
                        <div className="form-group">
                          <label>RUT</label>
                          <input
                            type="text"
                            id="rut"
                            name="rut"
                            className="form-control"
                            onChange={(e) => handleInput(e)}
                            placeholder="12.345.678-8"
                            value={inputs?.rut}
                          />
                        </div>
                        <Alert msg={msg} status={status} />
                      </div>
                      <div className="form-group text-center">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={Recaptcha_key}
                            style={{ display: "inline-block" }}
                            theme="dark"
                            size="invisible"
                        />
                        <Button
                          classes={"bt-ms _bt-green"}
                          handleLogin={handleRecover}
                          loader={loader}
                          name={"Recuperar"}
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-12 text-center">
                          <Link to={`/acepta-contrato`} className="recover tx _black">
                            Volver
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div></>) : (<><Layout classes={"ct-public"} bg={"bg-fluid-bottom"}>
            <div className="container-fluid">
              <div className="row">
                <div className="offset-md-3 col-md-6 bg-mobile">
                  <div className="ct-public__form">
                    <Title
                      title1={"Recuperar"}
                      title2={"Contraseña"}
                      subtitle={
                        "Ingresa tu RUT y enviaremos una clave provisoria al correo registrado."
                      }
                    />
                    <form>
                      <div className="content-form">
                        <div className="form-group">
                          <label>RUT</label>
                          <input
                            type="text"
                            id="rut"
                            name="rut"
                            className="form-control"
                            onChange={(e) => handleInput(e)}
                            placeholder="12.345.678-8"
                            value={inputs?.rut}
                          />
                        </div>
                        <Alert msg={msg} status={status} />
                      </div>
                      <div className="form-group text-center">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={Recaptcha_key}
                            style={{ display: "inline-block" }}
                            theme="dark"
                            size="invisible"
                        />
                        <Button
                          classes={"bt-ms _bt-green"}
                          handleLogin={handleRecover}
                          loader={loader}
                          name={"Recuperar"}
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-12 text-center">
                          <Link to={`/${BaseUrlNewSendero}`} className="recover tx _black">
                            Volver
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Layout></>)
        }
    
    </>
    
  );
};

export default Recover;
