import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import classes from "./FooterFixed.module.scss";
import call from "./../../assets/image/icon/call.svg";
import home from "./../../assets/image/icon/home.svg";
import notificaciones from "./../../assets/image/icon/notificaciones.svg";
import perfil from "./../../assets/image/icon/perfil.svg";
import whatsapp from "./../../assets/image/icon/whatsapp.svg";
import { BaseUrlNewSendero } from '../../../config';

const FooterFixed = () => {
  return (
    <div className={classes.content_footer_fix}>
      <Container>
        <Row>
          <Link to={`/${BaseUrlNewSendero}/resumen`} className={`${classes.col_mb}`}>
            <div>
              <img src={home} alt="Home" />
            </div>
            <p>Home</p>
          </Link>
          <a href="tel:6007363376" className={`${classes.col_mb}`}>
            <div>
              <img src={call} alt="Llamar a un ejecutivo" />
            </div>
            <p>Teléfono</p>
          </a>
          <a className={`${classes.col_mb}`} href={'https://wa.me/56937528099?text=Hola'} target='_blank' rel="noopener noreferrer">
            <div>
              <img src={whatsapp} alt="Whatsapp" />
            </div>
            <p>Whatsapp</p>
          </a>
          <div className={`${classes.col_mb}`}>
            <div>
              <img src={notificaciones} alt="Notificaciones" />
            </div>
            <p>Notificaciones</p>
          </div>
          <Link to={`/${BaseUrlNewSendero}/datos-personales`} className={`${classes.col_mb}`}>
            <div>
              <img src={perfil} alt="Perfil" />
            </div>
            <p>Perfil</p>
          </Link>
        </Row>
      </Container>
    </div>
  );
}

export default FooterFixed;