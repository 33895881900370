import React from "react";
import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={classes.contentFooter}>
      <footer className="text-center">
        <p>Término y condiciones</p>
        <p>Política de privacidad © 2021 Sendero.</p>
        <p>Todos los derechos reservados</p>
      </footer>
    </div>
  );
};

export default Footer;
