import React, { Component } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import AppSelect from './Select.jsx';
import AppInput from './InputText.jsx';
import Ciudad from './../Data/Ciudad.json';
import TipoProducto from './../Data/TipoProducto.json';
import TipoNecesidad from './../Data/TipoNecesidad.json';

class FormInput extends Component {

    render() {
        const { onChange, txtMsj, AlertClass, nameButton, disabledButton, setForm, validateSelect , value} = this.props;

        return (
            <>
                <Row className="row-form">
                    <Col xs={12} sm={12}><AppInput type="text" name="Nombre" placeholder="Nombre *" onChange={onChange} value ={value.Nombre} /></Col>
                    <Col xs={12} sm={6}><AppInput type="text" name="ApellidoP" placeholder="Apellido Paterno *" onChange={onChange}  value ={value.ApellidoP} /></Col>
                    <Col xs={12} sm={6}><AppInput type="text" name="ApellidoM" placeholder="Apellido Materno *" onChange={onChange} value ={value.ApellidoM} /></Col>
                    <Col xs={12} sm={6}><AppInput type="text" name="Rut" placeholder="Rut *" onChange={onChange} value ={value.Rut}/></Col>
                    <Col xs={12} sm={6}><AppInput type="email" name="Email" placeholder="Email *" onChange={onChange} value ={value.Email}/></Col>
                    <Col xs={3} sm={3}><AppInput type="number" name="Ext" placeholder="+56"  disabled/></Col>
                    <Col xs={9} sm={9}><AppInput type="text" name="Telefono" placeholder="Teléfono *" maxLength="9"  pattern="[0-9]{0,9}" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } else {
                        if(event.currentTarget.value.trim().length > 9) {
                            event.preventDefault();
                        }
                        } }} onChange={onChange} /></Col>
                    <Col xs={12} sm={12}><AppSelect name="RegionText" data={Ciudad} onChange={onChange} /></Col>
                    <Col xs={12} sm={12}><AppSelect name="TipoProductoText" validateSelect={validateSelect} data={TipoProducto} onChange={onChange} /></Col>
                    <Col xs={12} sm={12}><AppSelect name="TipoNecesidadText" data={TipoNecesidad} onChange={onChange} /></Col>
                    <Col xs={12} sm={12}><AppInput type="textarea" name="Mensaje" placeholder="Mensaje..." onChange={onChange} /></Col>
                    <Col xs={12} sm={12} className="text-center">
                        {txtMsj !== "" ? <Alert className={'alert ' + AlertClass}>{txtMsj}</Alert> : ""}
                    </Col>
                    <Col xs={12} sm={12} className="text-center">
                        <Button className="btn btn-verde button" onClick={setForm} disabled={disabledButton}>{nameButton}</Button>
                    </Col>
                </Row>
            </>
        );
    }
}
export default FormInput;