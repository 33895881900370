import React from 'react';
import { BrowserRouter, Route ,  Redirect , Switch } from 'react-router-dom'
import Home from './Home/Home';
import DatosPersonales from './Perfil/DatosPersonales.jsx';
import Contratos from './Contratos/Contratos';
import DetailAgreement from './DetailAgreement/DetailAgreement';
import CartolaPayments from './CartolaPago/CartolaPayments';
import PreguntasFrecuentes from './PreguntasFrecuentes/PreguntasFrecuentes';
import HistorialPago from './HistorialPago/HistorialPago';
import PagoPie from './PagoPie/PagoPie';
import { BaseUrlNewSendero } from '../../config';
import DetailPago from '../../components/DetailPago/DetailPago';

const routesPrivate = (props) => {
    let Path = "/" + BaseUrlNewSendero + "/";
    return (
        <BrowserRouter>
            <Route exact path={`${Path}resumen`} component={Home} />
            <Route exact path={`${Path}datos-personales`} component={DatosPersonales} />
            <Route exact path={`${Path}contratos`} component={Contratos} />
            <Route exact path={`${Path}detalle/:id`} component={DetailAgreement} />
            <Route exact path={`${Path}cartola-de-pago`} component={CartolaPayments} />
            <Route exact path={`${Path}cartola-de-pago/:id`} component={CartolaPayments} />
            <Route exact path={`${Path}preguntas-frecuentes`} component={PreguntasFrecuentes} />
            <Route exact path={`${Path}acepta-y-pagar-pie`} component={PagoPie} />
            <Route exact path={`${Path}historial/:id`} component={HistorialPago} />
            <Route path={`${Path}detail/:origen/:buy_order/:token/:status/:code`} component={DetailPago} />
            <Redirect to={`${Path}resumen`} />
        </BrowserRouter>
    );
}

export default routesPrivate;