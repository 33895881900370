import React, { useState } from 'react';
import Api from '../../../service/Api';
import { Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import RegionComuna from './../../../components/Data/RegionComuna.json';
import Validate from '../../../service/Validate';
import moment from 'moment/moment';
import './formulariocheckout.scss';

const FormularioCheckout = (props) => {
  // console.info(props);
  const [inputs, setInputs] = useState({
    necesidad: '',
    nombreCliente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    correo: '',
    rutCliente: '',
    telefonoFijoContacto: '',
    genero: '',
    estadoCivil: '',
    nacionalidad: '',
    regionCliente: '',
    comunaCliente: '',
    calle: '',
    numeroCalle: '',
    condiciones: false,
    data_producto: JSON.stringify({
      id: props.id,
      producto_codigo_id: props.codigo,
      title: props.title,
      precio: props.precio,
      uf: props.uf,
      precio_descuento: props.precio_descuento,
      precio_total: Math.round(
        props.precio_descuento * 0.19 + props.precio_descuento
      ),
    }),
  });

  const [preview, setpreview] = useState({
    disabled: false,
    spinner: false,
    opacity: 1,
  });

  const [comunas_selected, set_comunas_selected] = useState({});
  const [region_id_selected, set_region_id_selected] = useState({});
  const [url, setUrl] = useState('');
  const [token_ws, setToken] = useState('');
  const [popup, setPopUp] = useState(false);
  const [error, serError] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInput = (e) => {
    let valor = e.target.value;

    if (e.target.name == 'rutCliente') {
      valor = valor
        .replace(/[.-]/g, '')
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    }

    if (e.target.name == 'condiciones') {
      valor = e.target.checked;
    }

    setInputs({
      ...inputs,
      [e.target.name]: valor,
    });
  };
  const aceptaCondiciones = () => {
    this.setState({
      disabledButton: !this.state.disabledButton,
      modal: false,
    });
  };

  const comprobarMayoriaEdad = (dateSelected) => {
    var fechanacimiento = moment(dateSelected, 'YYYY-MM-DD');

    var years = moment().diff(fechanacimiento, 'years');

    return years >= 18 ? true : false;
  };

  const downloadPDF = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blanck';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const setForm = async () => {
    try {
      // this.setState({});
      setpreview({ disabled: true, spinner: true, opacity: 0.5 });
      setLoading(true);

      let emailRegex =
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

      if (inputs.rutCliente != '') {
        if (!Validate.validaRut(inputs.rutCliente.replaceAll('.', ''))) {
          serError([true, 'El Rut ingresado no es válido.']);
          return false;
        }
      } else {
        serError([true, 'Debe Ingresar Rut']);
        return false;
      }

      if (inputs.fechaNacimiento === '') {
        serError([true, 'Debe seleccionar Fecha de Nacimiento']);
        return false;
      }

      if (!comprobarMayoriaEdad(inputs.fechaNacimiento)) {
        serError([
          true,
          'La fecha de nacimiento debe ser mayor o igual a 18 años.',
        ]);
        return false;
      }

      if (inputs.necesidad === '') {
        serError([true, 'Debe seleccionar Necesidad']);
        return false;
      }
      if (inputs.nombreCliente === '') {
        serError([true, 'Debe ingresar Nombre']);
        return false;
      }
      if (inputs.apellidoPaterno === '') {
        serError([true, 'Debe ingresar Apellido Paterno']);
        return false;
      }
      if (inputs.apellidoMaterno === '') {
        serError([true, 'Debe ingresar Apellido Materno']);
        return false;
      }
      if (inputs.correo === '') {
        serError([true, 'Debe ingresar Correo Electrónico']);
        return false;
      }
      if (!emailRegex.test(inputs.correo)) {
        serError([true, 'Debe Ingresar un Correo Electrónico valido']);
        return false;
      }
      if (inputs.rutCliente === '') {
        serError([true, 'Debe Ingresar Rut']);
        return false;
      }
      // if (inputs.telefonoFijoContacto === "") {
      //   serError([true, "Debe Ingresar Teléfono"]);
      //   return false;
      // }

      // if (inputs.telefonoFijoContacto.length != 9 ) {
      //   serError([true, "El telefono debe tener 9 caracteres"]);
      //   return false;
      // }

      let rtel = Validate.isTelefonoOk(inputs.telefonoFijoContacto);
      if (!rtel.bresult) {
        serError([true, rtel.mresult]);
        return false;
      }

      if (inputs.telefonoFijoContacto === '') {
        serError([true, 'Debe Ingresar Teléfono']);
        return false;
      }

      if (inputs.genero === '' || parseInt(inputs.genero) === 0) {
        serError([true, 'Debe seleccionar Genero']);
        return false;
      }
      if (inputs.estadoCivil === '' || parseInt(inputs.estadoCivil) === 0) {
        serError([true, 'Debe seleccionar Estado Civil']);
        return false;
      }
      if (inputs.nacionalidad === '' || parseInt(inputs.nacionalidad) === 0) {
        serError([true, 'Debe seleccionar Nacionalidad']);
        return false;
      }
      if (inputs.regionCliente === '' || inputs.regionCliente === '-') {
        serError([true, 'Debe seleccionar Región']);
        return false;
      }
      if (inputs.comunaCliente === '' || parseInt(inputs.comunaCliente) === 0) {
        serError([true, 'Debe seleccionar Comuna']);
        return false;
      }
      if (inputs.calle === '') {
        serError([true, 'Debe Ingresar Calle / Pasaje']);
        return false;
      }
      if (inputs.numeroCalle === '') {
        serError([true, 'Debe Ingresar Número de calle']);
        return false;
      }
      if (inputs.condiciones != true) {
        serError([true, 'Debe aceptar las condiciones']);
        return false;
      }
      console.log(inputs);
      const response = await Api.setBuyPlan(inputs);
      console.info(response);
      if (response.result === 'success') {
        setToken(response.token_ws);
        setUrl(response.next_page);
        document.getElementById('form_webpay').submit();
      } else {
        if (response.result === 'error') {
          // this.setState({
          //   txtMsj: response.init,
          //   AlertClass: "alert-danger",
          //   disabledButton: false,
          // });
          serError([true, response.init]);
        } else {
          // this.setState({
          //   txtMsj: "Ocurrió un problema, favor intentar nuevamente",
          //   AlertClass: "alert-danger",
          //   disabledButton: false,
          // });
          serError([
            true,
            '[E] - Ocurrió un problema, favor intentar nuevamente',
          ]);
        }
      }
    } catch (err) {
      // this.setState({
      //   txtMsj: "Ocurrió un problema, favor intentar nuevamente",
      //   AlertClass: "alert-danger",
      //   disabledButton: false,
      // });
      console.log(error);
      serError([true, '[C] - Ocurrió un problema, favor intentar nuevamente.']);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setpreview({ disabled: false, spinner: false, opacity: 1 });
      }, 500);
    }
  };
  const onChangeRegion = (event) => {
    if (event.target.value === '-') {
      set_comunas_selected({});
      set_region_id_selected('-');
    } else {
      Object.keys(RegionComuna).forEach(function (key) {
        //arr.push(RegionComuna[key]);
        //console.log(event.target.value);
        if (RegionComuna[key].CODIGO_REGION == event.target.value) {
          // var comuna = RegionComuna[key].COMUNAS;
          set_comunas_selected(RegionComuna[key].COMUNAS);
          set_region_id_selected(RegionComuna[key].CODIGO_REGION);
        }
      });
    }
  };

  const renderPrecioswithIVA = (props) => {
    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <>
          <br />
          <div className="d-flex flex-column">
            <div
              className="d-flex flex-row justify-content-between"
              style={{ marginBottom: '0' }}
            >
              <label htmlFor="" style={{ marginBottom: '0', color: '#00A5C8' }}>
                PRECIO
              </label>
              <label htmlFor="" style={{ marginBottom: '0', color: '#000' }}>
                ${' '}
                {new Intl.NumberFormat('de-DE').format(props.precio_descuento)}
              </label>
            </div>
            <div
              className="d-flex flex-row justify-content-between"
              style={{ marginBottom: '0' }}
            >
              <label style={{ marginBottom: '0', color: '#00A5C8' }}>
                IVA A BENEFICIO FISCAL
              </label>
              <label style={{ marginBottom: '0', color: '#000' }}>
                $
                {new Intl.NumberFormat('de-DE').format(
                  Math.round(props.precio_descuento * 0.19)
                )}
              </label>
            </div>
            <div
              className="d-flex flex-row justify-content-between"
              style={{ marginBottom: '0' }}
            >
              <label
                style={{
                  marginBottom: '0',
                  color: '#00A5C8',
                  fontWeight: 'bold',
                }}
              >
                MONTO TOTAL A PAGAR
              </label>
              <label
                style={{
                  marginBottom: '0',
                  color: '#00A5C8',
                  fontWeight: 'bold',
                }}
              >
                $
                {new Intl.NumberFormat('de-DE').format(
                  Math.round(
                    props.precio_descuento * 0.19 + props.precio_descuento
                  )
                )}
              </label>
            </div>
          </div>
          <br />
          <div className="text-right">{valorTextoDisclaimer()}</div>
          <br />
        </>
      );
    }

    return (
      <>
        <div className="row box-resumen__precio my-3">
          <div className="col-3">Total</div>
          <div className="col-9 text-right">
            ${new Intl.NumberFormat('de-DE').format(props.precio_descuento)}* (
            {props.uf_descuento} UF)
            {valorTextoDisclaimer()}
          </div>
        </div>
      </>
    );
  };

  const valorTextoDisclaimer = () => {
    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <div className="disclaimer">
          <p>(*Valor variable, a la UF comercial del mes en transcurso.)</p>
          <p>En el próximo paso podrás seleccionar tu medio de pago y cuotas</p>
        </div>
      );
    }

    return (
      <div className="disclaimer">
        <p>(Monto variable a la uf comercial del mes en transcurso)</p>
        <p>En el próximo paso podrás seleccionar tu medio de pago y cuotas</p>
      </div>
    );
  };

  const renderDisclaimerIVA = () => {
    // <p className="disclaimer">
    //   <span style={{color: '#000',fontWeight: 'bold' , textAlign: 'justify' , opacity: '.5'}}>El precio indicado se encuentra afecto a</span> <span style={{color: '#000',fontWeight: 'bold' , textAlign: 'justify' }}>IVA A BENEFICIO FISCAL</span> <span style={{color: '#000',fontWeight: 'bold' , textAlign: 'justify' , opacity: '.5'}}>que Sendero se encuentra obligado a recaudar y entregar al Fisco.</span>
    // </p>

    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <>
          <p className="disclaimer">
            <span
              style={{
                color: '#627171',
                fontWeight: 'bold',
                textAlign: 'justify',
                opacity: '.5',
              }}
            >
              El precio indicado se encuentra afecto a{' '}
            </span>{' '}
            <span
              style={{
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'justify',
              }}
            >
              IVA A BENEFICIO FISCAL
            </span>{' '}
            <span
              style={{
                color: '#627171',
                fontWeight: 'bold',
                textAlign: 'justify',
                opacity: '.5',
              }}
            >
              {' '}
              que Sendero se encuentra obligado a recaudar y enterar al Fisco.
            </span>
          </p>
        </>
      );
    }

    return <></>;
  };

  var arr = [];
  var arr_comuna = [];
  Object.keys(RegionComuna).forEach(function (key) {
    arr.push(RegionComuna[key]);
  });
  Object.keys(comunas_selected).forEach(function (key) {
    arr_comuna.push(comunas_selected[key]);
  });

  return (
    <>
      <form name="form_webpay" action={url} method="POST" id="form_webpay">
        <input type="hidden" name="token_ws" value={token_ws} />
      </form>

      <div className="row">
        <div className="col-12 text-center">
          {preview.disabled ? (
            <>
              <Spinner animation="border" variant="primary" size="lg" />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="container checkout" style={{ opacity: preview.opacity }}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <h2>ELIJE UNA OPCIÓN</h2>
                <p className="tx-introduccion">Esta compra es para:</p>
              </div>
              <div className="col-6">
                <div className="box__ blue my-3">
                  <input
                    type={'radio'}
                    name="necesidad"
                    value="NF"
                    onChange={handleInput}
                    disabled={preview.disabled}
                  />{' '}
                  <span>Necesidad futura</span>
                </div>
              </div>
              <div className="col-6">
                <div className="box__ green my-3">
                  <input
                    type={'radio'}
                    name="necesidad"
                    value="NI"
                    onChange={handleInput}
                    disabled={preview.disabled}
                  />{' '}
                  <span>Necesidad inmediata</span>
                </div>
              </div>
            </div>
            <div className="form-contratar">
              <p className="tx-introduccion mb-2">
                Para completar tu compra debes ingresar los siguientes datos:
              </p>
              <form>
                <div className="row-form row">
                  <div className="col-12 col-sm-4">
                    <label>Nombre</label>
                    <input
                      className="form-control"
                      type="text"
                      name="nombreCliente"
                      id="nombreCliente"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-6 col-sm-4">
                    <label>Apellido Paterno</label>
                    <input
                      className="form-control"
                      type="text"
                      name="apellidoPaterno"
                      id="apellidoPaterno"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-6 col-sm-4">
                    <label>Apellido Materno</label>
                    <input
                      className="form-control"
                      type="text"
                      name="apellidoMaterno"
                      id="apellidoMaterno"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <label>Fecha de Nacimiento</label>
                    <input
                      className="form-control"
                      type="date"
                      name="fechaNacimiento"
                      id="fechaNacimiento"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-6 col-sm-6">
                    <label>Correo Electrónico</label>
                    <input
                      className="form-control"
                      type="email"
                      name="correo"
                      id="correo"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-12 col-sm-12">
                    <label>RUT</label>
                    <input
                      className="form-control"
                      type="text"
                      name="rutCliente"
                      id="rutCliente"
                      onChange={handleInput}
                      value={inputs.rutCliente || ''}
                      placeholder="11.111.111-1"
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-12 col-sm-12">
                    <label>Teléfono</label>
                    <input
                      disabled={preview.disabled}
                      className="form-control"
                      type="text"
                      name="telefonoFijoContacto"
                      id="telefonoFijoContacto"
                      onChange={handleInput}
                      pattern="[0-9]{0,10}"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        } else {
                          if (event.currentTarget.value.trim().length > 9) {
                            event.preventDefault();
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-6 col-sm-6">
                    <label>Género</label>
                    <select
                      className="form-control"
                      name="genero"
                      id="genero"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    >
                      <option value="0">Seleccione Género</option>
                      <option value="F">Femenino</option>
                      <option value="M">Masculino</option>
                    </select>
                  </div>
                  <div className="col-6 col-sm-6">
                    <label>Estado Civil</label>
                    <select
                      className="form-control"
                      name="estadoCivil"
                      id="estadoCivil"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    >
                      <option value="0">Seleccione Estado Civil</option>
                      <option value="S">Soltero(a)</option>
                      <option value="N">Unión Civil</option>
                      <option value="C">Casado(a)</option>
                      <option value="D">Divorciado(a)</option>
                      <option value="V">Viudo(a)</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-4">
                    <label>Nacionalidad</label>
                    <select
                      className="form-control"
                      name="nacionalidad"
                      id="nacionalidad"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    >
                      <option value="0">Seleccionar</option>
                      <option value="CH">Chilena</option>
                      <option value="EX">Extranjera</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-4">
                    <label>Región</label>
                    <select
                      className="form-control"
                      id="regionCliente"
                      name="regionCliente"
                      onChange={(e) => {
                        onChangeRegion(e);
                        handleInput(e);
                      }}
                      disabled={preview.disabled}
                    >
                      <option value="-">Seleccionar</option>
                      {arr.map((item, index) => (
                        <option key={index} value={item.CODIGO_REGION}>
                          {item.NOMBRE_REGION}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-sm-4">
                    <label>Comuna</label>
                    <select
                      className="form-control"
                      id="comunaCliente"
                      name="comunaCliente"
                      disabled={region_id_selected === '-' ? true : false}
                      onChange={handleInput}
                    >
                      <option value="0">Seleccionar</option>
                      {arr_comuna.map((item, index) => (
                        <option key={index} value={item.CODIGO_COMUNA}>
                          {item.NOMBRE_COMUNA}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-6 col-sm-6">
                    <label>Calle / Pasaje</label>
                    <input
                      className="form-control"
                      type="text"
                      name="calle"
                      id="calle"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="col-6 col-sm-6">
                    <label>Número de calle</label>
                    <input
                      className="form-control"
                      type="text"
                      name="numeroCalle"
                      id="numeroCalle"
                      onChange={handleInput}
                      disabled={preview.disabled}
                    />
                  </div>
                  <div className="text-left col-12 col-sm-12">
                    <p>
                      <input
                        type="checkbox"
                        name="condiciones"
                        onChange={handleInput}
                        value={inputs.condiciones}
                        disabled={preview.disabled}
                      />{' '}
                      He leído y acepto las condiciones especiales del{' '}
                      <span
                        onClick={() => downloadPDF(props?.pdf)}
                        className="acepta-condicion"
                      >
                        contrato.
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6 box-resumen">
            <h2 className="ml-3 mb-4">RESUMEN DE COMPRA</h2>
            <div className="checkout__box">
              <h2>{props.title}</h2>
              {/* <p
                className="box-detalle__icon_detalle"
                dangerouslySetInnerHTML={{
                  __html: `${props.detalle[0].description}`,
                }}
              ></p>
              <hr />
              <p
                dangerouslySetInnerHTML={{
                  __html: `${props.detalle[0].description_large}`,
                }}
              ></p>
              <br /> */}
              <div className="box-detalle">
                <p className="tx-blue">Servicio incluye:</p>
                {typeof props.servicio !== 'undefined' ? (
                  props.servicio.length > 0 &&
                  props.servicio.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className="box-detalle__icon_detalle"
                        dangerouslySetInnerHTML={{
                          __html: `${item.description}`,
                        }}
                      ></div>
                      <p>
                        Ánfora a disponibilidad del stock en el momento de
                        utilizar el servicio.
                      </p>
                      <br />
                      {renderDisclaimerIVA()}
                    </>
                  ))
                ) : (
                  <div className="box-detalle__icon_detalle">
                    <p>No tiene servicio disponible</p>
                  </div>
                )}
              </div>
              {renderPrecioswithIVA(props)}

              <div className="text-center">
                <div>
                  {error[0] && (
                    <div className="alert alert-danger">{error[1]}</div>
                  )}
                </div>
                <div className="content-button text-center">
                  <Button
                    className="btn-verde button"
                    onClick={setForm}
                    disabled={loading}
                    loading={loading}
                  >
                    Finalizar Compra
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={popup}
        toggle={() => setPopUp(!popup)}
        backdrop={'static'}
        fade={false}
        size="lg"
      >
        <ModalHeader toggle={() => setPopUp(!popup)}></ModalHeader>
        <ModalBody>
          <iframe src={props?.pdf} width="100%" height="500" />
          <Button onClick={() => setPopUp(!popup)}>Cerrar</Button>
        </ModalBody>
      </Modal>
    </>
  );
};
export default FormularioCheckout;
