import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Api from '../../service/Api';

import TitleSeccion from './titleSeccion';
import BannerTop from './../BannerTop';
import PaginationLaravel from './../PaginationLaravel';

import BannerNoticias from './../../assets/images/bannernoticias.jpg';
import BannerSenderoContigo from './../../assets/images/banner_sendero_contigo_1920x420.jpg';
import BannerSenderoContigoMobile from './../../assets/images/banner_sendero_contigo_mobil.jpg';


class PostsItems extends Component {
    constructor(props) {
        super(props);
        const title = this.props.title.split(' ');
        this.state = {
            categoria: props.type,
            ruta: parseInt(props.type) === 1 ? "noticias/" : "sendero-contigo/",
            cant: typeof props.cant === 'undefined' ? '' : props.cant,
            title: title,
            items: [],
            pagination: null
        }
        this.loadList = this.loadList.bind(this)
    }
    async componentWillMount() {
        this.loadList();
    }
    async loadList(page = {}) {
        let dataGet = { ...page, ...this.state }
        let response = await Api.getPosts(dataGet);
        let data = [];
        let pages = null;
        if (typeof response.data === 'undefined') {
            data = response;
        } else {
            data = response.data;
            pages = {
                last_page: response.last_page,
                current_page: response.current_page,
                per_page: response.per_page
            }
        }
        this.setState({
            items: data,
            pagination: pages
        });
    }

    render() {
        const { activarMenu, col } = this.props;
        let colunms = col === '' ? 4 : col;
        
        return (
            <>
                <Container fluid className="banner-notice">
                    {
                        this.state.cant <= 0
                            ? (
                                <>
                                    <div className="d-block d-md-none">
                                        <BannerTop
                                            title1={this.state.title[0]}
                                            title2={this.state.title[1]}
                                            image={parseInt(this.props.type) === 1 ? BannerNoticias : BannerSenderoContigoMobile} />
                                    </div>
                                    <div className="d-none d-md-block">
                                        <BannerTop
                                            title1={this.state.title[0]}
                                            title2={this.state.title[1]}
                                            image={parseInt(this.props.type) === 1 ? BannerNoticias : BannerSenderoContigo} />
                                    </div>
                                </>
                            ) :
                            null
                    }
                </Container>
                <Container>
                    <div className="grid-posts grid">
                        {parseInt(this.state.cant) === 3 ? (<TitleSeccion title={this.props.title} />) : null}
                        <Row>
                            {this.state.items.map((item, index) =>
                            (
                                <Col md={colunms} key={index}>
                                    <div className="content-blog">
                                        {/* <Link to={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")} onClick={activarMenu}></Link> */}
                                        <a href={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")} target="_blanck" onClick={activarMenu}><img src={item.portada} className="img-back-posts" alt={item.title} /></a>
                                        <div>
                                            {/* <Link to={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")} onClick={activarMenu} className="text-left content-blog__title">{item.title}</Link> */}
                                            <a href={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")}  target="_blanck" onClick={activarMenu} className="text-left content-blog__title">{item.title}</a>
                                            <p className="text-left content-blog__excerpt">{item.excerpt}</p>
                                            {/* <Link to={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")} className="link" onClick={activarMenu}>Leer</Link> */}
                                            <a href={"/posts/" + this.state.ruta + item.id + "/" + item.title.toLowerCase().split(" ").join("-")}  target="_blanck" onClick={activarMenu} style={{color:  '#94c01f'}}>Leer</a>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            {
                                this.state.pagination !== null ?
                                    <PaginationLaravel
                                        last_page={this.state.pagination.last_page}
                                        current_page={this.state.pagination.current_page}
                                        per_page={this.state.pagination.per_page}
                                        loadList={this.loadList}
                                        className=""
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </Container>
            </>
        );
    }
}
export default PostsItems;